import * as React from "react"
import ContactForm from "../components/ContactForm/ContactForm"
import Layout from "../components/layout"
import Seo from "../components/seo"

const HomePage = ({ location }) => {
  return (
    <Layout location={location}>
      <article>
				<div className="container md:mx-auto max-w-page py-4 md:py-8">
          <h1 className="text-3xl md:text-4xl font-alegreya">Get in touch</h1>
          <div className="grid md:grid-cols-2 gap-8 md:gap-16">
            <section>
              <p className="text-gray-600 md:text-lg">If you would like to get in touch to discuss how I can help you, please complete the form and click the 'Send message' button.</p>
              <p className="text-gray-600 md:text-lg"><strong>Please specify in your message if you would prefer to be contacted by telephone.</strong></p>
            </section>
            <ContactForm />
          </div>
        </div>
      </article>
    </Layout>
  )
}

export const Head = () => (
  <Seo title="Get in touch" />
)

export default HomePage