import * as React from "react"
import { navigate } from "gatsby"

const ContactForm = () => {

    function encode(data) {
        return Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
    }
    
    const [state, setState] = React.useState({})

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
            'form-name': form.getAttribute('name'),
            ...state,
            }),
        })
        .then(() => navigate(form.getAttribute('action')))
        .catch((error) => alert(error))
    }

    return (        
        <section>
            <form
                name="contact"
                method="post"
                action="/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
                >
                <input type="hidden" name="form-name" value="contact" />
                <p hidden>
                    <label>
                        Don’t fill this out: <input name="bot-field" onChange={handleChange} />
                    </label>
                </p>
                <p>
                    <label className="flex flex-col text-sm md:text-lg font-semibold text-gray-600">
                        <span className="mb-1">Your preferred name: </span><input type="text" name="name" required="required" onChange={handleChange} className="py-1 px-2 w-full text-sm md:text-lg border-2 border-gray-400 rounded" />
                    </label>
                </p>
                <p>
                    <label className="flex flex-col text-sm md:text-lg font-semibold text-gray-600">         
                        <span className="mb-1">Your pronouns (optional): </span><input type="text" name="pronouns" onChange={handleChange} className="py-1 px-2 w-full text-sm md:text-lg border-2 border-gray-400 rounded" />
                    </label>
                </p>
                <p>
                    <label className="flex flex-col text-sm md:text-lg font-semibold text-gray-600">         
                        <span className="mb-1">Your email: </span><input type="email" name="email" required="required" onChange={handleChange} className="py-1 px-2 w-full text-sm md:text-lg border-2 border-gray-400 rounded" />
                    </label>
                </p>
                <p>
                    <label className="flex flex-col text-sm md:text-lg font-semibold text-gray-600">         
                        <span className="mb-1">Your phone number (optional): </span><input type="text" name="phone" onChange={handleChange} className="py-1 px-2 w-full text-sm md:text-lg border-2 border-gray-400 rounded" />
                    </label>
                </p>
                <p>
                    <label className="flex flex-col text-sm md:text-lg font-semibold text-gray-600">
                        <span className="mb-1">Message: </span><textarea name="message" required="required" onChange={handleChange} className="h-40 py-1 px-2 w-full text-sm md:text-lg border-2 border-gray-400 rounded" />
                    </label>
                </p>
                <p>
                    <label className="text-gray-600">         
                        <input type="checkbox" name="consent" onChange={handleChange} required="required" className="text-lg border-2 border-gray-400 rounded mr-2" />By checking this box, you consent to being contacted using the information provided for the exclusive purpose of responding to your message.
                    </label>
                </p>
                <p>
                    <button type="submit" className="inline-block uppercase shadow-sm transition-all duration-200 border border-1 border-pink-700 md:border-pink-900 md:hover:border-pink-700 rounded py-2 px-4 md:px-8 bg-pink-900 md:bg-pink-custom md:hover:bg-pink-900 text-white font-semibold md:hover:text-white">Send message</button>
                </p>
            </form>
        </section>
    )
}

export default ContactForm